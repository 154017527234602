<template>
  <div>
    <div class="locale">
      <h2>榜单管理</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">测验</a></li>
        <li><a href="javascript:;">榜单</a></li>
        <li>查询</li>
      </ol>
      <router-link class="ant-btn ant-btn-primary" to="/test/rank/create"><a-icon type="plus"/> 添加榜单</router-link>
    </div>


    <div class="filter">
      <a-form-model layout="inline" @submit="filting" @submit.native.prevent>
        <a-form-model-item>
          <a-select v-model="filter.state" :options="option.states" />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="filter._word" placeholder="可输入关键字" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button html-type="submit">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

        <div class="box">
          <div class="box-body">
            <a-empty v-if="items.length == 0">
              <span slot="description"> 还没有任何榜单！</span>
              <router-link class="ant-btn ant-btn-primary" to="/test/rank/create"><a-icon type="plus"/> 添加新榜单</router-link>
            </a-empty>
            <table class="list" v-if="items.length > 0">
              <tr>
                <th style="width: 120px;">#</th>
                <th>主图</th>
                <th>名称</th>
                <th>总人数</th>
                <th>默认奖励</th>
                <th>时间范围</th>
                <th style="width: 12px;">状态</th>
                <th style="width: 120px;">发起时间</th>
                  <th style="width: 120px;"></th>
              </tr>
              <tr v-for="item in items">
                <td>{{item.id}}</td>
                <td>
                  <a :href="item.image" target="_blank">
                    <img v-if="item.image" alt="" class="roleimage" :src="item.image" />
                  </a>
                </td>
                <td>
                  <div>{{item.name}}</div>
                </td>
                <td>
                  <div>{{item.number}}</div>
                </td>
                <td>
                  <div>{{item.bonus.toFixed(2)}}</div>
                </td>
                <td>
                  <div>{{item.begin.format('yyyy-MM-dd')}} 起</div>
                  <div>{{item.end.format('yyyy-MM-dd')}} 止</div>
                </td>
                  <td>
                    <label class="label" :class="[item.state.value]">{{item.state.label}}</label>
                  </td>
                <td>
                  <div>{{item.created.format('yyyy-MM-dd hh:mm:ss')}}</div>
                  <!--              <div>{{item.created.format('hh:mm:ss')}}</div>-->
                </td>
                  <td>
                    <a-button-group size="small">
                      
                      <a-tooltip placement="top" title="编辑" v-if="item.state.value == STATE_PENDING">
                        <router-link class="ant-btn" :to="'/test/rank/change/'+item.id"><a-icon type="edit" /></router-link>
                      </a-tooltip>

                      <a-tooltip placement="top" title="颁奖" v-if="item.state.value == STATE_TOAWARD">
                        <router-link class="ant-btn" :to="'/test/rank/award/'+item.id"><a-icon type="pay-circle" style="color:green; font-weight: bold;" /></router-link>
                      </a-tooltip>


                      <a-tooltip placement="topRight" title="删除" v-if="item.state.value == STATE_PENDING">
                        <a-popconfirm placement="bottomLeft" title="确认要删除此榜单吗？" @confirm="remove(item)">
                          <a-button style="color:red;"><a-icon type="delete" /></a-button>
                        </a-popconfirm>
                      </a-tooltip>

                      <a-tooltip placement="top" title="详情" v-if="item.state.value != STATE_PENDING">
                          <router-link class="ant-btn" :to="'/test/rank/detail/'+item.id"><a-icon type="profile" /></router-link>
                      </a-tooltip>

                    </a-button-group>


                  </td>
              </tr>
            </table>
          </div>
        </div>





    <div class="pager">
      <a-pagination @change="paging" :page-size="paged.size" :default-current="paged.page" :total="paged.count" show-quick-jumper/>
    </div>


  </div>
</template>


<script>
  export default {
    name: 'Black',
    data() {
      return {
        first: true,
        option: {
          states: [ { label: '- 状态 -', value: ''} ],
          sizes: []
        },
        filter: {
          state: '',
        },
        paged: {
          offset: 0, limit: 1, count: 0, size: 10, page: 0
        },
        items: [],
        last: 1,

        batched: false,
      }
    },
    mounted() {
      this.request();
    },

    methods: {
      request(page, size) {
        let filter = {};
        for (let k in this.filter) {
          if (this.filter[k] && this.filter[k].value) {
            filter[k] = this.filter[k].value;
          } else {
            filter[k] = this.filter[k];
          }
        }

        filter._size = size || this.paged.size || 10;
        filter._index = page || 1;

        this.$get('/test/rank/filter', filter).then(res => {
          let ajax = res.data;
          if (ajax.data) {
            this.$util.imageField(ajax.data.items);
            this.$util.timeFields(ajax.data.items);
            
            
            if (this.first) {
              for (let k in this.option) {
                if (ajax.data.option[k]) {
                  this.option[k].push(...ajax.data.option[k])
                }
              }
            }

            this.items = ajax.data.items;
            this.paged = ajax.data.paged;
          }

          this.first = false;
          this.last = page;
        })
      },
      paging(page) {
        this.request(page);
      },
      filting() {
        this.request(1);
      },

      batch() {
        this.items.forEach((item)=>{
          item.batched = !this.batched;
        });
      },

        onState(item) {
          let url = (item.state.value == this.STATE_ONLINE) ? '/test/rank/offline/' : '/test/rank/online/';
          this.$post(url+item.id).then(res=>{
            let ajax = res.data;
            if (ajax.code == 0) {
              this.request(this.last);
              // item.state = ajax.data.item.state;
            }
          });
        },

        remove(item) {
            // this.$confirm('确认要删除此榜单吗？', ()=>{
                this.$post('/test/rank/remove/' + item.id).then(res=>{
                    let ajax = res.data;
                    if (ajax.code == 0) {
                        setTimeout(()=>{
                            this.request(this.last);
                        }, 500);
                    }
                });
            // })
        }
    }
  }
</script>

<style scoped lang="less">
  
  
  .roleimage {
    height: 40px;
  }

</style>